import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    'selectedCount',
    'actionCard',
    'actionCardContainer'
  ]

  updateCount() {
    const count = Array.from(this.actionCardContainerTarget.querySelectorAll('input[type=checkbox]'))
                       .filter(checkbox => checkbox.checked == true)
                       .length
    this.selectedCountTarget.textContent = this.selectedCountTarget.textContent.replace(/\d+/, count)
  }

  selectAll() {
    this.actionCardTargets.forEach(card => card.classList.add('is-checked'))
    this.updateCount()
  }

  selectNone() {
    this.actionCardTargets.forEach(card => card.classList.remove('is-checked'))
    this.updateCount()
  }
}