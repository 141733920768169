import { Controller } from "@hotwired/stimulus"
import { showV2, hideV2 } from "../utils/display"

const selectedRadioValue = (radioButtons) =>
  radioButtons.filter(button => button.checked)[0].value

export default class extends Controller {
  static targets = [
    'associationKindSelect',
    'equipmentSelect',
    'roomSelect',
    'selectLabel',
  ];

  connect() {
    if (this.hasRoomSelectTarget) {
      this.displayRoom();
    }
  }

  onAssociationKindChange() {
    if (selectedRadioValue(this.associationKindSelectTargets) === 'equipment') {
      this.displayEquipment();
    } else if (selectedRadioValue(this.associationKindSelectTargets) === 'room') {
      this.displayRoom();
    }
  }

  displayEquipment() {
    const label = this.equipmentSelectTarget.dataset.label
    showV2(this.equipmentSelectTarget)
    this.selectLabelTarget.innerHTML = label
    hideV2(this.roomSelectTarget.closest('.relative'))
    showV2(this.equipmentSelectTarget.closest('.relative'))
    this.roomSelectTarget.value = null
    hideV2(this.roomSelectTarget)
  }

  displayRoom() {
    const label = this.roomSelectTarget.dataset.label;
    showV2(this.roomSelectTarget);
    this.selectLabelTarget.innerHTML = label;
    hideV2(this.equipmentSelectTarget.closest('.relative'))
    showV2(this.roomSelectTarget.closest('.relative'))
    this.equipmentSelectTarget.value = null;
    hideV2(this.equipmentSelectTarget);
  }
}
