import { Controller } from "@hotwired/stimulus"
import { close, showV2, hideV2, isActivated, activate, desactivate } from '../utils/display'
import { useClickOutside } from "stimulus-use"

export default class extends Controller {
  static targets = [
    'select',
    'selected',
    'dropdown',
    'options',
    'option',
    'clearButton',
    'selectedContainer',
    'template'
  ]

  connect() {
    useClickOutside(this)

    let value = []
    this.multiple = this.selectTarget.multiple

    if (this.multiple) {
      for (const option of this.selectTarget.options) {
        if (option.selected && option.value !== '') {
          value.push({id: option.value, name: option.label})
          this.addTag(option.value, option.label)
          this.highlightSelectedOption(option.value)
        }
      }

      value.map(val => val.id).forEach(id => {
        activate([this.optionTargets.filter(option => option.dataset.searchableSelectValueParam == id)[0]])
      })
    } else {
      const selectedOption = this.selectTarget.selectedOptions[0]
      if (selectedOption && selectedOption.value !== '') {
        value = selectedOption.value
        this.selectedTarget.textContent = selectedOption.label
        this.selectedTarget.classList.remove('placeholder')
        this.highlightSelectedOption(selectedOption.value)
        activate([this.optionTargets.filter(item => item.dataset.searchableSelectValueParam == value)[0]])
      }
    }

    if (value.length && this.element.dataset.dispatch === 'true') {
      this.dispatch("initField", { detail: { field: this.element.dataset.field, value } })
    }
  }

  toggleDropdown(event) {
    if (this.canToggle(event)) {
      this.optionsTarget.classList.toggle('is-open')
    }
  }

  selectOption(event) {
    const {params, currentTarget} = event

    if (isActivated(currentTarget)) return

    let value = params.value
    activate([currentTarget])

    if (this.multiple) {
      this.addTag(params.value, currentTarget.innerHTML)
      this.highlightSelectedOption(params.value)
      value = [{id: value.toString(), name: currentTarget.innerHTML}]
    } else {
      this.selectTarget.value = params.value
      this.selectedTarget.textContent = currentTarget.dataset.label
      this.selectedTarget.classList.remove('placeholder')
      this.highlightSelectedOption(params.value)
      close(this.optionsTarget)
    }

    if (params.dispatch) {
      this.dispatch("fieldChanged", { detail: { field: params.field, value } })
    }
  }

  addTag(value, label) {
    const tagTemplate = this.templateTarget.content.cloneNode(true).childNodes[0]
    tagTemplate.childNodes[0].innerHTML = label
    tagTemplate.childNodes[1].dataset.searchableSelectValueParam = value
    this.selectedContainerTarget.appendChild(tagTemplate)

    for (const option of this.selectTarget.options) {
      if (option.value == value) { option.selected = true }
    }
  }

  clickOutside() {
    close(this.optionsTarget)
  }

  highlightSelectedOption(value) {
    const optionElement = this.optionTargets.find(option => option.dataset.searchableSelectValueParam == value)
    if (optionElement) {
      optionElement.classList.add('highlight')
    }
  }

  removeTag(event) {
    const value = event.params.value
    event.currentTarget.parentNode.remove()

    for (const option of this.selectTarget.options) {
      if (option.value == value) { option.selected = false }
    }
    desactivate([this.optionTargets.filter(option => option.dataset.searchableSelectValueParam == value)[0]])

    this.dispatch("clearField", { detail: { field: this.element.dataset.field, value: event.params.value } })

    const optionElement = this.optionTargets.find(option => option.dataset.searchableSelectValueParam == value)
    if (optionElement) {
      optionElement.classList.remove('highlight')
    }
  }

  search(event) {
    const searchText = event.currentTarget.value
    const list = this.optionsTarget.querySelectorAll('li:not(.is-hidden)')

    if (searchText.length < 2) {
      showV2(...list)
    } else {
      hideV2(...list)
      for (const item of list) {
        if (item.innerHTML.includes(searchText)) {
          showV2(item)
        }
      }
    }
  }

  canToggle(event) {
    if (this.hasClearButtonTarget) {
      return !this.clearButtonTarget.contains(event.target) && !event.currentTarget.classList.contains('is-disabled')
    }
    return !event.currentTarget.classList.contains('is-disabled')
  }

  clearSelect() {
    this.selectedTarget.classList.add('placeholder')
    this.selectedTarget.textContent = this.selectedTarget.dataset.placeholder
    if (this.hasClearButtonTarget) {
      hideV2(this.clearButtonTarget)
    }
  }
}
