import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    'headerHeight',
    'subheaderHeight',
    'mainContainerHeight',
    'tabContainerHeight',
    'summaryContainerHeight',
    'sectionFormHeight'
  ]

  connect() {
    this.setHeight();
    window.addEventListener('resize', () => {
      if (window.innerWidth >= 1024) {
        this.setHeight();
      }
    });
  }

  setHeight() {
    if (window.innerWidth >= 1024 && this.hasMainContainerHeightTarget) {
      const calculatedHeight = this.calculatedHeight()
      this.mainContainerHeightTarget.style.height = calculatedHeight;

      if (this.hasSummaryContainerHeightTarget) {
        this.summaryContainerHeightTarget.style.height = `calc(${calculatedHeight} - 80px)`;
      }
    } else if (this.hasMainContainerHeightTarge) {
      this.mainContainerHeightTarget.style.height = 'auto'

      if (this.hasSummaryContainerHeightTarget) {
        this.summaryContainerHeightTarget.style.height = 'auto';
      }
    }
  }

  calculatedHeight() {
    const headerHeight    = this.headerHeightTarget.offsetHeight || 0;
    let calculatedHeight  = `calc(100vh - ${headerHeight}px`;

    if (this.hasSubheaderHeightTarget) {
      const subheaderHeight = this.subheaderHeightTarget.offsetHeight || 0;
      calculatedHeight = `calc(100vh - ${headerHeight}px - ${subheaderHeight}px`;

      if (this.hasTabContainerHeightTarget) {
        const tabContainerHeight = this.tabContainerHeightTarget.offsetHeight || 0;
        calculatedHeight = `calc(100vh - ${tabContainerHeight}px - ${subheaderHeight}px - ${headerHeight}px)`;
      } 
    }

    return calculatedHeight
  }

  setFormHeight() {
    this.sectionFormHeightTarget.style.height = window.innerWidth >= 1024 ? this.calculatedHeight() : 'auto'

  }
}