import { Controller } from '@hotwired/stimulus'
import { showV2, hideV2, isHiddenV2 } from '../utils/display'

const selectCheckbox = (checkbox) =>
  checkbox.parentElement.parentElement.classList.add('is-checked')

const unselectCheckbox = (checkbox) =>
  checkbox.parentElement.parentElement.classList.remove('is-checked')

export default class extends Controller {
  static targets = ['checkbox', 'selectAll', 'panel', 'counter', 'link']

  reset() {
    this.checkboxTargets.forEach((checkbox) => {
      checkbox.checked = false
      unselectCheckbox(checkbox)
    })

    this.count = 0
  }

  updateCounter(count) {
    this.count = count
    this.counterTarget.innerHTML = this.count

    if (this.element.dataset.context === 'createCompany') {
      !!this.count ? this.dispatch("selected") : this.dispatch("unselected")
    }
  }

  updatedUrl(url, field, values) {
    const params = values.map(value => `bulk_action[${field}][]=${value}`).join('&')
    const new_url = new URL(url)

    new_url.searchParams.delete(`bulk_action[${field}][]`)

    return !!new_url.search ? `${new_url.href}&${params}` : `${new_url.href}?${params}`
  }

  updateModal() {
    const checked = this.checkboxTargets.filter(checkbox => checkbox.checked)
    this.updateCounter(checked.length)
    
    if (checked.length) {
      const field = checked[0].dataset.field
      const values = checked.map(element => element.dataset.value)
      this.linkTargets.forEach(link => link.href = this.updatedUrl(link.href, field, values))
    }
  }

  selectAll(event) {
    if (event.target.checked) {
      showV2(this.panelTarget)
      this.checkboxTargets.forEach((checkbox) => {
        if (!checkbox.disabled) {
          checkbox.checked = true
          selectCheckbox(checkbox)
        }
      });
    } else {
      hideV2(this.panelTarget)
      this.checkboxTargets.forEach((checkbox) => {
        checkbox.checked = false
        unselectCheckbox(checkbox)
      })
    }
    this.updateModal()
  }
  
  select(event) {
    this.updateModal()

    if (!event.target.checked) {
      unselectCheckbox(event.target)

      if (this.selectAllTarget.checked) {
        this.selectAllTarget.checked = false
      }

      if (!this.count && !isHiddenV2(this.panelTarget)) {
        hideV2(this.panelTarget)
      }
    }

    if (event.target.checked) {
      selectCheckbox(event.target)

      if (this.count === this.checkboxTargets.length) {
        this.selectAllTarget.checked = true
      }
  
      if (isHiddenV2(this.panelTarget)) {
        showV2(this.panelTarget)
      }
    }
  }

  close() {
    this.selectAllTarget.checked = false
    this.checkboxTargets.forEach((checkbox) => {
      checkbox.checked = false
      unselectCheckbox(checkbox)
    })
    hideV2(this.panelTarget)
  }

  isCheckbox(target) {
    return (this.checkboxTargets + this.selectAllTarget).includes(target) ||
      (this.checkboxTargets + this.selectAllTarget).includes(target.control)
  }
}