import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['form']

  dispatchCloseSidePanel(event) {
    if (event.detail.success) {
      this.dispatch('closeSidePanel')
    }
  }

  submit() {
    this.formTarget.requestSubmit()
  }
}