import { Controller } from '@hotwired/stimulus'
import { close, showV2, hideV2 } from '../utils/display'
import { useClickOutside } from 'stimulus-use'

export default class extends Controller {
  static targets = ['select', 'selected', 'dropdown', 'options', 'clearButton']

  connect() {
    useClickOutside(this,{element: this.dropdownTarget})

    if (this.selectTarget.selectedOptions[0].value) {
      this.selectedTarget.textContent = this.selectTarget.selectedOptions[0].label
      if (this.hasClearButtonTarget) {
        showV2(this.clearButtonTarget)
      }
    } else if (this.selectTarget.required) {
      const option = !!this.selectTarget.options[0].value ? this.selectTarget.options[0] :  this.selectTarget.options[1]
      this.selectTarget.value = option.value
      this.selectedTarget.textContent = option.label
    } else {
      this.clear()
    }

    if (this.element.dataset.dispatch === 'true') {
      this.dispatch("initField", { detail: { field: this.element.dataset.field, value: this.selectTarget.value } })
    }
  }

  toggleDropdown(event) {
    if(this.canToggle(event)){
      if (!this.dropdownTarget.classList.contains('disabled')) {
        this.optionsTarget.classList.toggle('is-open');
      }
    }
  }

  selectOption(event) {
    if (!this.dropdownTarget.classList.contains('disabled')) {
      this.selectTarget.value = event.params.value
      this.selectedTarget.textContent = event.currentTarget.dataset.label
      this.selectedTarget.classList.remove('placeholder')

      if (this.hasClearButtonTarget) {
        showV2(this.clearButtonTarget)
      }

      const stepValue = event.currentTarget.dataset.stepValue;
      if (event.params.dispatch) {
        this.dispatch("fieldChanged", { detail: { field: event.params.field, value: event.params.value } })
        this.dispatch("stepValueChanged", { detail: { stepValue } })
      }

      close(this.optionsTarget);
    }
  }

  clickOutside() {
    close(this.optionsTarget);
  }

  clearSelect() {
    this.selectTarget.selectedIndex = 0
    this.clear()
    this.dispatch("fieldChanged", { detail: { value: '' } })
  }

  canToggle(event) {
    if (this.hasClearButtonTarget) {
      return !this.clearButtonTarget.contains(event.target) && !event.currentTarget.classList.contains('is-disabled')
    }
    return !event.currentTarget.classList.contains('is-disabled')
  }

  clear() {
    this.selectedTarget.classList.add('placeholder')
    this.selectedTarget.textContent = this.selectedTarget.dataset.placeholder
    if (this.hasClearButtonTarget) {
      hideV2(this.clearButtonTarget)
    }
  }
}
