import { Controller } from "@hotwired/stimulus"
import { showV2, hideV2 } from "../utils/display"

export default class extends Controller {
  static targets = ['step', 'roomToEvaluate', 'duplication', 'duplicationLabel']

  connect() {
    this.updateDuplicationLabel({detail: {field: 'kind', value: 'annual_evaluation'}})
  }

  showStep(index) {
    this.stepTargets.forEach((step, i) => {
      if (i === index) {
        showV2(step);
      } else {
        hideV2(step);
      }
    });
  }

  nextStep() {
    const currentIndex = this.stepTargets.findIndex(step => !step.classList.contains("is-hidden"));
    if (currentIndex < this.stepTargets.length - 1) {
      this.showStep(currentIndex + 1);
    }
  }

  updateRoomToEvaluate({detail}) {
    if (detail.field === 'form_establishment[nb_rooms_measurable]') {
      let roomsToEvaluate = detail.value
      if (detail.value > 5) { roomsToEvaluate = Math.max(5, Math.round(detail.value / 2.0)) }
      if (detail.value > 39) { roomsToEvaluate = 20 }

      this.roomToEvaluateTarget.getElementsByTagName('input')[0].value = roomsToEvaluate
    }
  }

  updateDuplicationLabel({detail}) {
    if (detail.field ==='kind' && this.hasDuplicationTarget) {
      const { annualEvaluation, selfDiagnosis } = this.duplicationLabelTarget.dataset

      if (annualEvaluation && detail.value == 'annual_evaluation') {
        this.duplicationLabelTarget.textContent = this.duplicationLabelTarget.textContent.replace(/form_name/,`Évaluation des moyens d'aération ${annualEvaluation}`)
        showV2(this.duplicationTarget)
      } else if (selfDiagnosis && detail.value == 'self_diagnosis') {
        this.duplicationLabelTarget.textContent = this.duplicationLabelTarget.textContent.replace(/form_name/,`Auto-diagnostic ${selfDiagnosis}`)
        showV2(this.duplicationTarget)
      } else {
        hideV2(this.duplicationTarget)
      }
    }
  }
}