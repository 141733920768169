import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['tooltip']

  connect() {
    this.tooltip = this.element.querySelector(".tooltip")

    this.boundShowTooltip = this.positioningTooltip.bind(this)
    this.element.addEventListener("mouseenter", this.boundShowTooltip)
  }

  disconnect() {
    this.element.removeEventListener("mouseenter", this.boundShowTooltip)
  }

  positioningTooltip() {
    this.tooltip.style.top = `-${this.tooltip.offsetHeight + 4}px` // Adjust the value according to the need
  }
}