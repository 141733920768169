import { Controller } from "@hotwired/stimulus";
import { hideV2, showV2 } from "../utils/display";

const initType = 'custom-select:initField'

const setSelected = (field, value, selectedTargets) => {
  const selectedInput = selectedTargets.filter(element => element.dataset.field === field)[0]
  switch (value) {
    case 'noOption':
      if (selectedInput.dataset.multiple != 'true') {
        selectedInput.innerHTML = selectedInput.dataset.noOptionPlaceholder
      } else {
        selectedInput.innerHTML = ''
      }
      break
    case 'placeholder':
      if (selectedInput.dataset.multiple != 'true') {
        selectedInput.classList.add('placeholder')
        selectedInput.innerHTML = selectedInput.dataset.placeholder
      }
      break
    default:
      selectedInput.classList.remove('placeholder')
      if (selectedInput.dataset.multiple != 'true') {
        selectedInput.innerHTML = value
      }
      break
  }
}

const enableDropdown = (field, dropdownTargets) => {  
  dropdownTargets.filter(element => element.dataset.field === field)[0].classList.remove('is-disabled')
}

const disableDropdown = (field, dropdownTargets) => {
  dropdownTargets.filter(element => element.dataset.field === field)[0].classList.add('is-disabled')
}

const toggleOptions = (options, field, value) => {
  const optionItems = options.filter(({ dataset }) => dataset.associatedField === field)
                             .flatMap(({ children }) => Array.from(children))
  hideV2(...optionItems)
  showV2(...optionItems.filter(({ dataset }) => dataset.fieldId == value))
}

export default class extends Controller {
  static targets = ['options', 'select', 'selected', 'dropdown']

  updateSelect(event) {
    const {field, value}  = event.detail

    toggleOptions(this.optionsTargets, field, value)
    
    const selectsList = this.selectTargets.filter((select) => select.dataset.associatedField === field)
    selectsList.forEach(select => {
      const availableOptions = this.optionsTargets.filter(option => option.dataset.field === select.dataset.field)[0].querySelectorAll("li:not(.is-hidden)")
      const defaultOption = availableOptions.length ? availableOptions[0].dataset : {}
      this.setSelect(select, defaultOption, event.type)
    }, this)
  }

  setSelect(select, option, eventType) {
    const { field } = select.dataset

    if (select.value && eventType === initType) {
      setSelected(field, select.selectedOptions[0].label, this.selectedTargets)
      enableDropdown(field, this.dropdownTargets)
    } else {
      if (select.required) {
        select.value = option.customSelectValueParam
        setSelected(field, option.label, this.selectedTargets)
        enableDropdown(field, this.dropdownTargets)
      } else if (option.label) {
        select.selectedIndex = 0
        setSelected(field, 'placeholder', this.selectedTargets)
        enableDropdown(field, this.dropdownTargets)
      } else {
        select.selectedIndex = 0
        setSelected(field, 'noOption', this.selectedTargets)
        disableDropdown(field, this.dropdownTargets)
      }
    } 
  }
}
